import React from "react"
import RacepageTdF from "../components/racepageTdF"

const Race2021 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Tour de France Femmes",
      edition: 2022,
      what: "Race data",
      updated: "2022-07-25 14:09:09",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21872: {
        teamId: "21872",
        teamUciCode: "FDJ",
        teamName: "FDJ-SUEZ-Futuroscope",
        teamNationCode: "FRA",
      },
      21877: {
        teamId: "21877",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "NED",
      },
      21881: {
        teamId: "21881",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      22011: {
        teamId: "22011",
        teamUciCode: "SDW",
        teamName: "Team SD Worx",
        teamNationCode: "NED",
      },
      22296: {
        teamId: "22296",
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      22809: {
        teamId: "22809",
        teamUciCode: "BEX",
        teamName: "Team BikeExchange-Jayco",
        teamNationCode: "AUS",
      },
      23744: {
        teamId: "23744",
        teamUciCode: "CSR",
        teamName: "CANYON//SRAM Racing",
        teamNationCode: "GER",
      },
      23811: {
        teamId: "23811",
        teamUciCode: "WNT",
        teamName: "Ceratizit-WNT Pro Cycling",
        teamNationCode: "GER",
      },
      24281: {
        teamId: "24281",
        teamUciCode: "AGI",
        teamName: "AG Insurance-NXTG Team",
        teamNationCode: "NED",
      },
      24283: {
        teamId: "24283",
        teamUciCode: "PHV",
        teamName: "Parkhotel Valkenburg",
        teamNationCode: "NED",
      },
      27553: {
        teamId: "27553",
        teamUciCode: "UXT",
        teamName: "Uno-X Pro Cycling Team",
        teamNationCode: "NOR",
      },
      27874: {
        teamId: "27874",
        teamUciCode: "LIV",
        teamName: "Liv Racing Xstra",
        teamNationCode: "NED",
      },
      27895: {
        teamId: "27895",
        teamUciCode: "JVW",
        teamName: "Team Jumbo-Visma",
        teamNationCode: "NED",
      },
      27898: {
        teamId: "27898",
        teamUciCode: "ARK",
        teamName: "Ark\u00e9a Pro Cycling Team",
        teamNationCode: "FRA",
      },
      27926: {
        teamId: "27926",
        teamUciCode: "TIB",
        teamName: "EF Education-TIBCO-SVB",
        teamNationCode: "USA",
      },
      27951: {
        teamId: "27951",
        teamUciCode: "LEW",
        teamName: "Le Col Wahoo",
        teamNationCode: "GBR",
      },
      28095: {
        teamId: "28095",
        teamUciCode: "COF",
        teamName: "Cofidis Women Team",
        teamNationCode: "FRA",
      },
      28240: {
        teamId: "28240",
        teamUciCode: "SRC",
        teamName: "Stade Rochelais Charente Maritime",
        teamNationCode: "FRA",
      },
      28260: {
        teamId: "28260",
        teamUciCode: "HPW",
        teamName: "Human Powered Health",
        teamNationCode: "USA",
      },
      28433: {
        teamId: "28433",
        teamUciCode: "CGS",
        teamName: "Roland Cogeas Edelweiss Squad",
        teamNationCode: "SUI",
      },
      28536: {
        teamId: "28536",
        teamUciCode: "AUB",
        teamName: "St Michel-Auber 93",
        teamNationCode: "FRA",
      },
      28537: {
        teamId: "28537",
        teamUciCode: "PLP",
        teamName: "Plantur-Pura",
        teamNationCode: "BEL",
      },
      28954: {
        teamId: "28954",
        teamUciCode: "VAL",
        teamName: "Valcar-Travel & Service",
        teamNationCode: "ITA",
      },
      28991: {
        teamId: "28991",
        teamUciCode: "UAD",
        teamName: "UAE Team ADQ",
        teamNationCode: "UAE",
      },
    },
    riders: {
      89431: {
        id: 89431,
        startno: 111,
        firstName: "Rachel",
        lastName: "Neylan",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1982-03-09",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 97,
          overallPosition: 98,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      91184: {
        id: 91184,
        startno: 32,
        firstName: "Grace",
        lastName: "Brown",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1992-07-07",
        teamId: 21872,
        teamName: "FDJ-SUEZ-Futuroscope",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 58,
          overallPosition: 58,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89538: {
        id: 89538,
        startno: 64,
        firstName: "Tiffany",
        lastName: "Cromwell",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1988-07-06",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 11,
          overallPosition: 11,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      96034: {
        id: 96034,
        startno: 172,
        firstName: "Anya",
        lastName: "Louw",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "2000-10-16",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 119,
          overallPosition: 120,
          stageTime: "00:00:56",
          overallTime: "00:01:06",
        },
      },
      89433: {
        id: 89433,
        startno: 81,
        firstName: "Amanda",
        lastName: "Spratt",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1987-09-17",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 126,
          overallPosition: 127,
          stageTime: "00:00:56",
          overallTime: "00:01:06",
        },
      },
      89430: {
        id: 89430,
        startno: 83,
        firstName: "Alexandra",
        lastName: "Manly",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1996-02-28",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 17,
          overallPosition: 17,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      112278: {
        id: 112278,
        startno: 84,
        firstName: "Ruby",
        lastName: "Roseman-Gannon",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-11-08",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 42,
          overallPosition: 42,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      113725: {
        id: 113725,
        startno: 163,
        firstName: "Nicole",
        lastName: "Frain",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1992-08-24",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 12,
          overallPosition: 12,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      91068: {
        id: 91068,
        startno: 96,
        firstName: "Kathrin",
        lastName: "Schweinberger",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1996-10-29",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 99,
          overallPosition: 100,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      91067: {
        id: 91067,
        startno: 145,
        firstName: "Christina",
        lastName: "Schweinberger",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1996-10-29",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 76,
          overallPosition: 77,
          stageTime: "00:00:15",
          overallTime: "00:00:25",
        },
      },
      90686: {
        id: 90686,
        startno: 155,
        firstName: "Jesse",
        lastName: "Vandenbulcke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-01-17",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 96,
          overallPosition: 97,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      90111: {
        id: 90111,
        startno: 114,
        firstName: "Alana",
        lastName: "Castrique",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-05-08",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "N",
        overall: { position: 999, time: "00:00:00" },
        "stage-1": {
          stagePosition: 999,
          stageTime: "00:00:00",
          overallTime: "-2:06:10",
        },
      },
      92988: {
        id: 92988,
        startno: 174,
        firstName: "Lone",
        lastName: "Meertens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-04-16",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 112,
          overallPosition: 113,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      89883: {
        id: 89883,
        startno: 22,
        firstName: "Lotte",
        lastName: "Kopecky",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-11-10",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 3,
          overallPosition: 3,
          stageTime: "00:00:00",
          overallTime: "00:00:06",
        },
      },
      89807: {
        id: 89807,
        startno: 124,
        firstName: "Valerie",
        lastName: "Demey",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-01-17",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 45,
          overallPosition: 45,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      114776: {
        id: 114776,
        startno: 141,
        firstName: "Julie",
        lastName: "De Wilde",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2002-12-08",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 15,
          overallPosition: 15,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      101796: {
        id: 101796,
        startno: 142,
        firstName: "Sanne",
        lastName: "Cant",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-10-08",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 13,
          overallPosition: 13,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89986: {
        id: 89986,
        startno: 143,
        firstName: "Kim",
        lastName: "de Baat",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-05-29",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 127,
          overallPosition: 128,
          stageTime: "00:00:56",
          overallTime: "00:01:06",
        },
      },
      89534: {
        id: 89534,
        startno: 62,
        firstName: "Alena",
        lastName: "Amialiusik",
        nationCode: "BLR",
        nationName: "Belarus",
        birthDate: "1989-02-06",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 102,
          overallPosition: 103,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      100618: {
        id: 100618,
        startno: 211,
        firstName: "Simone",
        lastName: "Boilard",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "2000-07-21",
        teamId: 28536,
        teamName: "St Michel-Auber 93",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 8,
          overallPosition: 8,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      97208: {
        id: 97208,
        startno: 101,
        firstName: "Olivia",
        lastName: "Baril",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1997-10-10",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 41,
          overallPosition: 41,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      106424: {
        id: 106424,
        startno: 136,
        firstName: "Magdeleine",
        lastName: "Vallieres Mill",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "2001-08-10",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 125,
          overallPosition: 126,
          stageTime: "00:00:56",
          overallTime: "00:01:06",
        },
      },
      91260: {
        id: 91260,
        startno: 15,
        firstName: "Paula",
        lastName: "Pati\u00f1o",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-03-29",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 35,
          overallPosition: 35,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89480: {
        id: 89480,
        startno: 16,
        firstName: "Arlenis",
        lastName: "Sierra",
        nationCode: "CUB",
        nationName: "Cuba",
        birthDate: "1992-12-07",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 69,
          overallPosition: 69,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89778: {
        id: 89778,
        startno: 233,
        firstName: "Antri",
        lastName: "Christoforou",
        nationCode: "CYP",
        nationName: "Cyprus",
        birthDate: "1992-04-02",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 80,
          overallPosition: 81,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      89554: {
        id: 89554,
        startno: 31,
        firstName: "Cecilie Uttrup",
        lastName: "Ludwig",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-08-23",
        teamId: 21872,
        teamName: "FDJ-SUEZ-Futuroscope",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 27,
          overallPosition: 27,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89743: {
        id: 89743,
        startno: 193,
        firstName: "Julie",
        lastName: "Leth",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1992-07-13",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 91,
          overallPosition: 92,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      92093: {
        id: 92093,
        startno: 14,
        firstName: "Emma",
        lastName: "Norsgaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1999-07-26",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 5,
          overallPosition: 5,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89597: {
        id: 89597,
        startno: 13,
        firstName: "Sheyla",
        lastName: "Gutierrez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-01-01",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 107,
          overallPosition: 108,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      90596: {
        id: 90596,
        startno: 71,
        firstName: "Mavi",
        lastName: "Garcia",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1984-01-02",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 30,
          overallPosition: 30,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89450: {
        id: 89450,
        startno: 85,
        firstName: "Ane",
        lastName: "Santesteban",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-12-12",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 129,
          overallPosition: 129,
          stageTime: "00:01:40",
          overallTime: "00:01:50",
        },
      },
      90868: {
        id: 90868,
        startno: 92,
        firstName: "Sandra",
        lastName: "Alonso Dominguez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1998-08-19",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 131,
          overallPosition: 131,
          stageTime: "00:01:40",
          overallTime: "00:01:50",
        },
      },
      100561: {
        id: 100561,
        startno: 156,
        firstName: "Gladys",
        lastName: "Verhulst",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-01-02",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 117,
          overallPosition: 118,
          stageTime: "00:00:29",
          overallTime: "00:00:39",
        },
      },
      107600: {
        id: 107600,
        startno: 212,
        firstName: "Alison",
        lastName: "Avoine",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2000-01-05",
        teamId: 28536,
        teamName: "St Michel-Auber 93",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 137,
          overallPosition: 137,
          stageTime: "00:04:07",
          overallTime: "00:04:17",
        },
      },
      91406: {
        id: 91406,
        startno: 213,
        firstName: "Sandrine",
        lastName: "Bideau",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1989-04-12",
        teamId: 28536,
        teamName: "St Michel-Auber 93",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 94,
          overallPosition: 95,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      89919: {
        id: 89919,
        startno: 214,
        firstName: "Coralie",
        lastName: "Demay",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-10-10",
        teamId: 28536,
        teamName: "St Michel-Auber 93",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 78,
          overallPosition: 79,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      104126: {
        id: 104126,
        startno: 215,
        firstName: "Barbara",
        lastName: "Fonseca",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-11-29",
        teamId: 28536,
        teamName: "St Michel-Auber 93",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 101,
          overallPosition: 102,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      113564: {
        id: 113564,
        startno: 216,
        firstName: "Margot",
        lastName: "Pompanon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-04-20",
        teamId: 28536,
        teamName: "St Michel-Auber 93",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 140,
          overallPosition: 140,
          stageTime: "00:09:38",
          overallTime: "00:09:48",
        },
      },
      89736: {
        id: 89736,
        startno: 3,
        firstName: "Audrey",
        lastName: "Cordon-Ragot",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1989-09-22",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 44,
          overallPosition: 44,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89825: {
        id: 89825,
        startno: 51,
        firstName: "Juliette",
        lastName: "Labous",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-11-04",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 33,
          overallPosition: 33,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      96692: {
        id: 96692,
        startno: 113,
        firstName: "Victoire",
        lastName: "Berteau",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2000-08-16",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 20,
          overallPosition: 20,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      100582: {
        id: 100582,
        startno: 116,
        firstName: "Sandra",
        lastName: "Levenez",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1979-07-05",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 141,
          overallPosition: 141,
          stageTime: "00:10:26",
          overallTime: "00:10:36",
        },
      },
      98849: {
        id: 98849,
        startno: 115,
        firstName: "Valentine",
        lastName: "Fortin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-04-24",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 116,
          overallPosition: 117,
          stageTime: "00:00:29",
          overallTime: "00:00:39",
        },
      },
      90106: {
        id: 90106,
        startno: 36,
        firstName: "Evita",
        lastName: "Muzic",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-05-26",
        teamId: 21872,
        teamName: "FDJ-SUEZ-Futuroscope",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 48,
          overallPosition: 48,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      96734: {
        id: 96734,
        startno: 35,
        firstName: "Marie",
        lastName: "Le Net",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2000-01-25",
        teamId: 21872,
        teamName: "FDJ-SUEZ-Futuroscope",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 25,
          overallPosition: 25,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89763: {
        id: 89763,
        startno: 182,
        firstName: "Pauline",
        lastName: "Allin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-05-02",
        teamId: 27898,
        teamName: "Ark\u00e9a Pro Cycling Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 111,
          overallPosition: 112,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      98950: {
        id: 98950,
        startno: 181,
        firstName: "Morgane",
        lastName: "Coston",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-12-28",
        teamId: 27898,
        teamName: "Ark\u00e9a Pro Cycling Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 87,
          overallPosition: 88,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      104035: {
        id: 104035,
        startno: 184,
        firstName: "Amandine",
        lastName: "Fouquenet",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2001-02-19",
        teamId: 27898,
        teamName: "Ark\u00e9a Pro Cycling Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 62,
          overallPosition: 62,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      93081: {
        id: 93081,
        startno: 185,
        firstName: "Ana\u00efs",
        lastName: "Morichon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-10-06",
        teamId: 27898,
        teamName: "Ark\u00e9a Pro Cycling Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 136,
          overallPosition: 136,
          stageTime: "00:03:26",
          overallTime: "00:03:36",
        },
      },
      89927: {
        id: 89927,
        startno: 186,
        firstName: "Greta",
        lastName: "Richioud",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-10-11",
        teamId: 27898,
        teamName: "Ark\u00e9a Pro Cycling Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 92,
          overallPosition: 93,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      89917: {
        id: 89917,
        startno: 12,
        firstName: "Aude",
        lastName: "Biannic",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-03-27",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 118,
          overallPosition: 119,
          stageTime: "00:00:31",
          overallTime: "00:00:41",
        },
      },
      89922: {
        id: 89922,
        startno: 201,
        firstName: "S\u00e9verine",
        lastName: "Eraud",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-02-24",
        teamId: 28240,
        teamName: "Stade Rochelais Charente Maritime",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 46,
          overallPosition: 46,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      100584: {
        id: 100584,
        startno: 202,
        firstName: "No\u00e9mie",
        lastName: "Abgrall",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-12-01",
        teamId: 28240,
        teamName: "Stade Rochelais Charente Maritime",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 132,
          overallPosition: 132,
          stageTime: "00:01:40",
          overallTime: "00:01:50",
        },
      },
      93083: {
        id: 93083,
        startno: 203,
        firstName: "India",
        lastName: "Grangier",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2000-01-05",
        teamId: 28240,
        teamName: "Stade Rochelais Charente Maritime",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 65,
          overallPosition: 65,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      107583: {
        id: 107583,
        startno: 206,
        firstName: "Maeva",
        lastName: "Squiban",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2002-03-19",
        teamId: 28240,
        teamName: "Stade Rochelais Charente Maritime",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 57,
          overallPosition: 57,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      92687: {
        id: 92687,
        startno: 93,
        firstName: "Laura",
        lastName: "Asencio",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-05-14",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 86,
          overallPosition: 87,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      131572: {
        id: 131572,
        startno: 106,
        firstName: "Margaux",
        lastName: "Vigie",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-07-21",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 37,
          overallPosition: 37,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89641: {
        id: 89641,
        startno: 151,
        firstName: "Elizabeth",
        lastName: "Holden",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1997-09-12",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 56,
          overallPosition: 56,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      96817: {
        id: 96817,
        startno: 52,
        firstName: "Pfeiffer",
        lastName: "Georgi",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2000-09-27",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 36,
          overallPosition: 36,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      90984: {
        id: 90984,
        startno: 191,
        firstName: "Joscelin",
        lastName: "Lowden",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1987-10-03",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 100,
          overallPosition: 101,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      89535: {
        id: 89535,
        startno: 192,
        firstName: "Hannah",
        lastName: "Barnes",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1993-05-04",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 124,
          overallPosition: 125,
          stageTime: "00:00:56",
          overallTime: "00:01:06",
        },
      },
      93655: {
        id: 93655,
        startno: 42,
        firstName: "Anna",
        lastName: "Henderson",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1998-11-14",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 43,
          overallPosition: 43,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89672: {
        id: 89672,
        startno: 204,
        firstName: "Natalie",
        lastName: "Grinczer",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1993-11-15",
        teamId: 28240,
        teamName: "Stade Rochelais Charente Maritime",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 89,
          overallPosition: 90,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      110027: {
        id: 110027,
        startno: 53,
        firstName: "Franziska",
        lastName: "Koch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2000-07-13",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 114,
          overallPosition: 115,
          stageTime: "00:00:29",
          overallTime: "00:00:39",
        },
      },
      89826: {
        id: 89826,
        startno: 55,
        firstName: "Liane",
        lastName: "Lippert",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1998-01-13",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 40,
          overallPosition: 40,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      96725: {
        id: 96725,
        startno: 194,
        firstName: "Hannah",
        lastName: "Ludwig",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2000-02-15",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 113,
          overallPosition: 114,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      114479: {
        id: 114479,
        startno: 223,
        firstName: "Hannah",
        lastName: "Buch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2002-09-11",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 139,
          overallPosition: 139,
          stageTime: "00:08:09",
          overallTime: "00:08:19",
        },
      },
      89536: {
        id: 89536,
        startno: 91,
        firstName: "Lisa",
        lastName: "Brennauer",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1988-06-08",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 19,
          overallPosition: 19,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89839: {
        id: 89839,
        startno: 134,
        firstName: "Kathrin",
        lastName: "Hammes",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1989-01-09",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 105,
          overallPosition: 106,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      93088: {
        id: 93088,
        startno: 146,
        firstName: "Laura",
        lastName: "S\u00fc\u00dfemilch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1997-02-23",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 138,
          overallPosition: 138,
          stageTime: "00:05:48",
          overallTime: "00:05:58",
        },
      },
      89447: {
        id: 89447,
        startno: 44,
        firstName: "Romy",
        lastName: "Kasper",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1988-05-05",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 73,
          overallPosition: 73,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89424: {
        id: 89424,
        startno: 224,
        firstName: "Rotem",
        lastName: "Gafinovitz",
        nationCode: "ISR",
        nationName: "Israel",
        birthDate: "1992-06-09",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 121,
          overallPosition: 122,
          stageTime: "00:00:56",
          overallTime: "00:01:06",
        },
      },
      103237: {
        id: 103237,
        startno: 234,
        firstName: "Barbara",
        lastName: "Malcotti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-02-19",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 79,
          overallPosition: 80,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      89714: {
        id: 89714,
        startno: 1,
        firstName: "Elisa",
        lastName: "Balsamo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-02-27",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 7,
          overallPosition: 7,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89745: {
        id: 89745,
        startno: 2,
        firstName: "Elisa",
        lastName: "Longo Borghini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1991-12-10",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 22,
          overallPosition: 22,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      90862: {
        id: 90862,
        startno: 112,
        firstName: "Martina",
        lastName: "Alzini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-02-10",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 142,
          overallPosition: 142,
          stageTime: "00:12:02",
          overallTime: "00:12:12",
        },
      },
      89716: {
        id: 89716,
        startno: 33,
        firstName: "Marta",
        lastName: "Cavalli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-03-18",
        teamId: 21872,
        teamName: "FDJ-SUEZ-Futuroscope",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 50,
          overallPosition: 50,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      96818: {
        id: 96818,
        startno: 34,
        firstName: "Vittoria",
        lastName: "Guazzini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-12-26",
        teamId: 21872,
        teamName: "FDJ-SUEZ-Futuroscope",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 10,
          overallPosition: 10,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89448: {
        id: 89448,
        startno: 65,
        firstName: "Soraya",
        lastName: "Paladin",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-04",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 108,
          overallPosition: 109,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      96873: {
        id: 96873,
        startno: 173,
        firstName: "Gaia",
        lastName: "Masetti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-10-26",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 70,
          overallPosition: 70,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89443: {
        id: 89443,
        startno: 72,
        firstName: "Marta",
        lastName: "Bastianelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-04-30",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 55,
          overallPosition: 55,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89489: {
        id: 89489,
        startno: 76,
        firstName: "Erica",
        lastName: "Magnaldi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-08-24",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 67,
          overallPosition: 67,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89984: {
        id: 89984,
        startno: 94,
        firstName: "Maria Giulia",
        lastName: "Confalonieri",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-03-30",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 71,
          overallPosition: 71,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89983: {
        id: 89983,
        startno: 102,
        firstName: "Alice Maria",
        lastName: "Arzuffi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-11-19",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 64,
          overallPosition: 64,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      114539: {
        id: 114539,
        startno: 103,
        firstName: "Eleonora Camilla",
        lastName: "Gasparrini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2002-03-25",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 16,
          overallPosition: 16,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89719: {
        id: 89719,
        startno: 104,
        firstName: "Silvia",
        lastName: "Persico",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-07-25",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 14,
          overallPosition: 14,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89497: {
        id: 89497,
        startno: 105,
        firstName: "Ilaria",
        lastName: "Sanguineti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-04-15",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 74,
          overallPosition: 74,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89595: {
        id: 89595,
        startno: 122,
        firstName: "Rachele",
        lastName: "Barbieri",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-02-21",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 4,
          overallPosition: 4,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89776: {
        id: 89776,
        startno: 132,
        firstName: "Letizia",
        lastName: "Borghesi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-10-16",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 31,
          overallPosition: 31,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89410: {
        id: 89410,
        startno: 23,
        firstName: "Christine",
        lastName: "Majerus",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1987-02-25",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 135,
          overallPosition: 135,
          stageTime: "00:03:15",
          overallTime: "00:03:25",
        },
      },
      90014: {
        id: 90014,
        startno: 232,
        firstName: "Nina",
        lastName: "Buijsman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-11-16",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 104,
          overallPosition: 105,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      90117: {
        id: 90117,
        startno: 235,
        firstName: "Marit",
        lastName: "Raaijmakers",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-06-02",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 68,
          overallPosition: 68,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      90112: {
        id: 90112,
        startno: 154,
        firstName: "Marjolein",
        lastName: "Van 't Geloof",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-03-27",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 47,
          overallPosition: 47,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      189692: {
        id: 189692,
        startno: 152,
        firstName: "Eva",
        lastName: "Van Agt",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-03-18",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 77,
          overallPosition: 78,
          stageTime: "00:00:15",
          overallTime: "00:00:25",
        },
      },
      96709: {
        id: 96709,
        startno: 153,
        firstName: "Maike",
        lastName: "van der Duin",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-09-12",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 6,
          overallPosition: 6,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89832: {
        id: 89832,
        startno: 6,
        firstName: "Ellen",
        lastName: "Van Dijk",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-02-11",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 75,
          overallPosition: 76,
          stageTime: "00:00:15",
          overallTime: "00:00:25",
        },
      },
      114476: {
        id: 114476,
        startno: 5,
        firstName: "Shirin",
        lastName: "van Anrooij",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2002-02-05",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 90,
          overallPosition: 91,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      93400: {
        id: 93400,
        startno: 54,
        firstName: "Charlotte",
        lastName: "Kool",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-05-06",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 54,
          overallPosition: 54,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      90120: {
        id: 90120,
        startno: 56,
        firstName: "Lorena",
        lastName: "Wiebes",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-03-17",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 1,
          overallPosition: 1,
          stageTime: "01:54:00",
          overallTime: "01:53:50",
        },
      },
      90019: {
        id: 90019,
        startno: 66,
        firstName: "Pauliena",
        lastName: "Rooijakkers",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-05-12",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 72,
          overallPosition: 72,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      114498: {
        id: 114498,
        startno: 175,
        firstName: "Ilse",
        lastName: "Pluimers",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2002-04-29",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 49,
          overallPosition: 49,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89434: {
        id: 89434,
        startno: 11,
        firstName: "Annemiek",
        lastName: "van Vleuten",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1982-10-08",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 21,
          overallPosition: 21,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      93562: {
        id: 93562,
        startno: 21,
        firstName: "Demi",
        lastName: "Vollering",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-11-15",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 23,
          overallPosition: 23,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89405: {
        id: 89405,
        startno: 26,
        firstName: "Chantal",
        lastName: "van den Broek-Blaak",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1989-10-22",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 61,
          overallPosition: 61,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89416: {
        id: 89416,
        startno: 41,
        firstName: "Marianne",
        lastName: "Vos",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-05-13",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 2,
          overallPosition: 2,
          stageTime: "00:00:00",
          overallTime: "00:00:04",
        },
      },
      89420: {
        id: 89420,
        startno: 43,
        firstName: "Riejanne",
        lastName: "Markus",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1994-09-01",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 83,
          overallPosition: 84,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      90025: {
        id: 90025,
        startno: 46,
        firstName: "Karlijn",
        lastName: "Swinkels",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-10-28",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 115,
          overallPosition: 116,
          stageTime: "00:00:29",
          overallTime: "00:00:39",
        },
      },
      89522: {
        id: 89522,
        startno: 74,
        firstName: "Maaike",
        lastName: "Boogaard",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-08-24",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 51,
          overallPosition: 51,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89422: {
        id: 89422,
        startno: 121,
        firstName: "Jeanne",
        lastName: "Korevaar",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-09-24",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 29,
          overallPosition: 29,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89964: {
        id: 89964,
        startno: 123,
        firstName: "Thalita",
        lastName: "De Jong",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-11-06",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 106,
          overallPosition: 107,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      96753: {
        id: 96753,
        startno: 125,
        firstName: "Silke",
        lastName: "Smulders",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-04-01",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 66,
          overallPosition: 66,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89831: {
        id: 89831,
        startno: 126,
        firstName: "Sabrina",
        lastName: "Stultiens",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-07-08",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 93,
          overallPosition: 94,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      89423: {
        id: 89423,
        startno: 144,
        firstName: "Yara",
        lastName: "Kastelijn",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-08-09",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 28,
          overallPosition: 28,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      96741: {
        id: 96741,
        startno: 161,
        firstName: "Femke",
        lastName: "Gerritse",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-05-14",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 110,
          overallPosition: 111,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      96726: {
        id: 96726,
        startno: 162,
        firstName: "Mischa",
        lastName: "Bredewold",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2000-06-20",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 103,
          overallPosition: 104,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      95821: {
        id: 95821,
        startno: 164,
        firstName: "Femke",
        lastName: "Markus",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-11-17",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 38,
          overallPosition: 38,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      110343: {
        id: 110343,
        startno: 165,
        firstName: "Quinty",
        lastName: "Schoens",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-02-12",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 95,
          overallPosition: 96,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      99107: {
        id: 99107,
        startno: 166,
        firstName: "Anne",
        lastName: "Van Rooijen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2000-06-04",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 130,
          overallPosition: 130,
          stageTime: "00:01:40",
          overallTime: "00:01:50",
        },
      },
      93003: {
        id: 93003,
        startno: 195,
        firstName: "Mie Bj\u00f8rndal",
        lastName: "Ottestad",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1997-07-17",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 88,
          overallPosition: 89,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      114768: {
        id: 114768,
        startno: 196,
        firstName: "Anne Dorthe",
        lastName: "Ysland",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "2002-04-09",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 26,
          overallPosition: 26,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      113731: {
        id: 113731,
        startno: 231,
        firstName: "Henrietta",
        lastName: "Christie",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "2002-01-23",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 82,
          overallPosition: 83,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      136057: {
        id: 136057,
        startno: 176,
        firstName: "Ally",
        lastName: "Wollaston",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "2001-01-04",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 39,
          overallPosition: 39,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89419: {
        id: 89419,
        startno: 61,
        firstName: "Katarzyna",
        lastName: "Niewiadoma",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1994-09-29",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 18,
          overallPosition: 18,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      92703: {
        id: 92703,
        startno: 95,
        firstName: "Marta",
        lastName: "Lach",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1997-05-26",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 85,
          overallPosition: 86,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      89555: {
        id: 89555,
        startno: 24,
        firstName: "Ashleigh",
        lastName: "Moolman-Pasio",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1985-12-09",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 34,
          overallPosition: 34,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      127642: {
        id: 127642,
        startno: 205,
        firstName: "Frances",
        lastName: "Janse Van Rensburg",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "2001-01-10",
        teamId: 28240,
        teamName: "Stade Rochelais Charente Maritime",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 122,
          overallPosition: 123,
          stageTime: "00:00:56",
          overallTime: "00:01:06",
        },
      },
      138754: {
        id: 138754,
        startno: 221,
        firstName: "Tamara",
        lastName: "Dronova",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1993-08-13",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 9,
          overallPosition: 9,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89523: {
        id: 89523,
        startno: 75,
        firstName: "Eugenia",
        lastName: "Bujak",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1989-06-25",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 52,
          overallPosition: 52,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89533: {
        id: 89533,
        startno: 86,
        firstName: "Urska",
        lastName: "Zigart",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1996-12-04",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 109,
          overallPosition: 110,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      89528: {
        id: 89528,
        startno: 73,
        firstName: "Ursa",
        lastName: "Pintar",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1985-10-03",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 60,
          overallPosition: 60,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      92095: {
        id: 92095,
        startno: 63,
        firstName: "Elise",
        lastName: "Chabbey",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-04-24",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 24,
          overallPosition: 24,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      90392: {
        id: 90392,
        startno: 222,
        firstName: "Caroline",
        lastName: "Baur",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1994-04-17",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 120,
          overallPosition: 121,
          stageTime: "00:00:56",
          overallTime: "00:01:06",
        },
      },
      159727: {
        id: 159727,
        startno: 225,
        firstName: "Petra",
        lastName: "Stiasny",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "2001-09-10",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "N",
        overall: { position: 999, time: "00:00:00" },
        "stage-1": {
          stagePosition: 997,
          stageTime: "00:00:00",
          overallTime: "-2:06:10",
        },
      },
      92485: {
        id: 92485,
        startno: 25,
        firstName: "Marlen",
        lastName: "Reusser",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1991-09-20",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 53,
          overallPosition: 53,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      96857: {
        id: 96857,
        startno: 45,
        firstName: "Noemi",
        lastName: "R\u00fcegg",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "2001-04-19",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 134,
          overallPosition: 134,
          stageTime: "00:02:44",
          overallTime: "00:02:54",
        },
      },
      96746: {
        id: 96746,
        startno: 171,
        firstName: "Julia",
        lastName: "Borgstr\u00f6m",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "2001-06-09",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 63,
          overallPosition: 63,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      102007: {
        id: 102007,
        startno: 183,
        firstName: "Yuliia",
        lastName: "Biriukova",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "1998-01-24",
        teamId: 27898,
        teamName: "Ark\u00e9a Pro Cycling Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 84,
          overallPosition: 85,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      90107: {
        id: 90107,
        startno: 236,
        firstName: "Lily",
        lastName: "Williams",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1994-06-24",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 133,
          overallPosition: 133,
          stageTime: "00:02:22",
          overallTime: "00:02:32",
        },
      },
      89801: {
        id: 89801,
        startno: 4,
        firstName: "Leah",
        lastName: "Thomas",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1989-05-30",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 123,
          overallPosition: 124,
          stageTime: "00:00:56",
          overallTime: "00:01:06",
        },
      },
      132707: {
        id: 132707,
        startno: 82,
        firstName: "Kristen",
        lastName: "Faulkner",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1992-12-18",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 128,
          overallPosition: 75,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      177299: {
        id: 177299,
        startno: 131,
        firstName: "Veronica",
        lastName: "Ewers",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1994-09-01",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 59,
          overallPosition: 59,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      89596: {
        id: 89596,
        startno: 133,
        firstName: "Krista",
        lastName: "Doebel-Hickok",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1989-04-28",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 32,
          overallPosition: 32,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
      },
      90125: {
        id: 90125,
        startno: 135,
        firstName: "Emily",
        lastName: "Newsom",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1983-09-04",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 81,
          overallPosition: 82,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
      89503: {
        id: 89503,
        startno: 226,
        firstName: "Olga",
        lastName: "Zabelinskaya",
        nationCode: "UZB",
        nationName: "Uzbekistan",
        birthDate: "1980-05-10",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 98,
          overallPosition: 99,
          stageTime: "00:00:17",
          overallTime: "00:00:27",
        },
      },
    },
  }

  const race = "Tour de France"
  const raceID = 17

  return (
    <RacepageTdF
      title={`${race} 2021`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2021
